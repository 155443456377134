<template>
  <div id="event-details" class="h-100" v-if="event">
    <div class="d-flex justify-content-center mt-5 mb-5">
      <img class="logo img-fluid" src="@/assets/media/icons/logo.png" alt="" />
    </div>

    <div class="event-body mt-5">
      <template>
        <v-app>
          <b-tabs content-class="mt-3">
            <b-tab :title="$t('EVENT.EVENT_DETAILS.ADD_QUESTIONS')">
              <b-alert
                show
                v-if="
                  roles.includes('ROLE_ADMIN') &&
                    event.hasQuestionnaire &&
                    event.count > 0
                "
                >You cannot edit or add any questions to this event
                anymore</b-alert
              >

              <EventQuestions
                :event="event"
                v-if="roles.includes('ROLE_ADMIN') && event.hasQuestionnaire"
              />
              <b-alert
                show
                variant="success"
                v-if="roles.includes('ROLE_ADMIN') && !event.hasQuestionnaire"
                >This event doesn't have questionnaire</b-alert
              >
            </b-tab>
          </b-tabs>
          <template></template>
        </v-app>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import EventQuestions from "@/components/events/EventQuestions.vue";

export default {
  created() {
    this.getEvent({ id: this.$route.params.id });
    this.SET_DOCTOR(null);
  },
  components: {
    EventQuestions,
  },
  data() {
    return {
      doctorExist: null,
    };
  },
  methods: {
    ...mapMutations({
      SET_DOCTOR: "SET_DOCTOR",
    }),
    ...mapActions({
      getEvent: "getEvent",
    }),
    formatDate(date) {
      const today = new Date(date);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "-" + mm + "-" + yyyy;

      return formattedToday;
    },
  },
  computed: {
    ...mapGetters({
      event: "event",
      roles: "roles",
    }),
  },
};
</script>

<style lang="scss" scoped>
#event-details {
  .logo {
    width: 130px;
  }

  .event-data {
    padding: 12px 20px;
    padding-top: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    max-width: 700px;
    margin-inline: auto;
    border-radius: 12px;
    margin-top: 50px;
    h1 {
      font-weight: 500;
      font-size: 21px;
      color: #21285f;
      text-align: center;
    }

    .head {
      width: 100%;
    }

    .event-item {
      background-color: #f2f8fa;
      padding: 12px;
      border-radius: 12px;
      display: flex;
      align-items: center;

      > div {
        // margin-left: 100px;
        flex: 1;

        &.right-marg {
          margin-right: 80px;
        }

        &.left-marg {
          margin-right: 0;
          margin-left: 55px;
        }
      }

      img {
        width: 60px;
      }

      label {
        font-weight: 500;
        font-size: 16px;
        color: #424eaa;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
    }
  }
}
</style>
