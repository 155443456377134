<template>
  <div id="event-questions-section" v-if="questions.length > 0">
    <template
      v-if="
        roles.includes('ROLE_ADMIN') &&
          event.hasQuestionnaire &&
          event.count <= 0
      "
    >
      <h3 class="mt-4">
        {{ $t("EVENT.EVENT_DETAILS.ADD_QUESTIONS") }}
      </h3>
      <div class="question-section mt-5">
        <div class="row">
          <div class="col-6">
            <v-autocomplete
              filled
              rounded
              solo
              v-model="selectedQuestion"
              :items="questions"
              item-text="question"
              item-value="id"
              :placeholder="$t('EVENT.EVENT_DETAILS.SELECT_LABEL_QUESTION')"
            ></v-autocomplete>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-3">
            <b-button @click="addQuestion" class="btn-primary">{{
              $t("COMMON.ADD")
            }}</b-button>
          </div>
        </div>
      </div>
    </template>

    <div v-if="eventQuestions.length > 0" class="mt-4 event-question-section">
      <hr />
      <h3 class="mb-3">Questions</h3>
      <b-card
        v-for="(question, idx) in eventQuestions"
        :key="'question-' + idx"
        tag="article"
        class="mb-2"
      >
        <div class="d-flex justify-content-between">
          <div>
            <b-card-title>
              {{ question.question }}
            </b-card-title>
            <b-card-sub-title
              v-for="(opt, idx) in question.options"
              :key="'option-' + idx"
              class="mb-2"
            >
              {{ opt.text }}
            </b-card-sub-title>
          </div>
          <i
            @click="deleteQuestion(question.id)"
            class="fa fa-times delete-btn"
            aria-hidden="true"
          ></i>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    this.getQuestions();
    this.getEventQuestions({ eventId: this.$route.params.id });
  },
  props: {
    event: {
      type: Object,
    },
  },
  methods: {
    ...mapActions({
      getQuestions: "getQuestions",
      addQuestionToEvent: "addQuestionToEvent",
      getEventQuestions: "getEventQuestions",
      deleteEventQuestion: "deleteEventQuestion",
    }),
    addQuestion() {
      const questionData = {
        id: this.selectedQuestion,
      };

      this.addQuestionToEvent({
        questionData,
        vm: this,
        eventId: this.$route.params.id,
      });
    },
    resetFields() {
      this.selectedQuestion = null;
    },
    deleteQuestion(questionId) {
      if (confirm("Are you sure you want to delete this question ?")) {
        this.deleteEventQuestion({
          vm: this,
          eventId: this.$route.params.id,
          questionId,
        });
      } else return false;
    },
  },
  data() {
    return {
      selectedQuestion: null,
    };
  },
  computed: {
    ...mapGetters({
      questions: "questions",
      eventQuestions: "eventQuestions",
      roles: "roles",
    }),
  },
};
</script>

<style lang="scss" scoped>
#event-questions-section {
  h3 {
    font-weight: 500;
    font-size: 16px;
  }

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }

  .btn-primary {
    width: 200px;
  }

  .card-subtitle {
    display: list-item;
    list-style-type: square;
    list-style-position: inside;
  }

  .delete-btn {
    cursor: pointer;
    &:hover {
      color: #3c4eba;
    }
  }
}
</style>
